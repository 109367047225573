import React from 'react'
import PropTypes from 'prop-types'
import {Container, Row, Col} from 'react-bootstrap'
import parse from 'html-react-parser';
import { useStaticQuery, graphql, Link } from "gatsby"
import Heading from '../../../components/ui/heading'
import Text from '../../../components/ui/text'
import Button from '../../../components/ui/button'
import Image from '../../../components/image'
import {CaseStudyWrap, ImageWrap, CaseStudyTextWrap, ButtonWrap} from './assurance.style'

const Assurance = ({titleStyle, textStyle}) => {
    const qdata = useStaticQuery(graphql `
    query CaseQuery2 {
        img: file(relativePath: { eq: "shield.png" }) {
        
            childImageSharp {
              fluid(maxWidth: 922, maxHeight: 760, quality: 100){
                presentationWidth
                presentationHeight
                ...GatsbyImageSharpFluid
              }
            }

        }
    }      
    `);
    //console.log(3434334)
    //console.log(qdata)
    const data = qdata.img;
    const imageFluid = qdata.img.childImageSharp.fluid;

    return (
        <CaseStudyWrap>
            <Container fluid>
                <Row className="align-items-center">
                    <Col lg={6}>
                        {/*<ImageWrap>
                            <Image
                                fluid={imageFluid}
                                align="right"
                            />
                        </ImageWrap>*/}
                        <Row>
                            <CaseStudyTextWrap>
                                <Heading {...titleStyle}>
                                    {/*Vi jobber med alle de vesentligste&raquo;
                                    <span>garantiutstederne</span> i markedet*/}
                                    <span>Like garantivilkår</span> uavhengig av utsteder
                                </Heading>
                                <Text {...textStyle}>
                                    Vilkårene for garantiforsikring er like uavhengig forsikringsselskap, men
                                    de ulike utstederne har ulikt fokus med tanke på egenskapene til kundene og størrelsen
                                    på garantiene  de er villige til å utstede.
                                    Vi innhenter pris fra de leverandører som er villige til å utstede
                                    garantier for deres bedrifts behov - slik at dere kan sammenligne tilbud på garantiforsikring.
                                </Text>
                                <br/><br/>
                            </CaseStudyTextWrap>
                        </Row>
                    </Col>
                    <Col lg={6}>
                        {/*<Row>
                            <CaseStudyTextWrap>
                                <Heading {...titleStyle}>

                                    <span>Like garantivilkår</span> uavhengig av utsteder
                                </Heading>
                                <Text {...textStyle}>
                                    Vilkårene for garantiforsikring er like uavhengig forsikringsselskap, men
                                    de ulike utstederne har ulikt fokus med tanke på egenskapene til kundene og størrelsen
                                    på garantiene  de er villige til å utstede.
                                    Vi innhenter pris fra de leverandører som er villige til å utstede
                                    garantier for deres bedrifts behov - slik at dere kan sammenligne tilbud på garantiforsikring.
                                </Text>
                                <br/><br/>
                            </CaseStudyTextWrap>
                        </Row>*/}
                        <Row>
                            <CaseStudyTextWrap>
                                <Heading {...titleStyle}>
                                    {/*Vi jobber med alle de vesentligste&raquo;
                                <span>garantiutstederne</span> i markedet*/}
                                    <span>Solide</span> og <span>godkjente</span> forsikringsselskaper
                                </Heading>
                                <Text {...textStyle}>
                                    Vi innhenter tilbud utelukkende fra solide og godkjente forsikringsselskaper.
                                    De selskapene som omtales i denne tjenesten er selskaper som har
                                    blitt godkjent av de største aktørene i bransjen.
                                </Text>
                                <ButtonWrap>
                                    <Button as={Link} to={"/tilbud-garantiforsikring"}>Sammenlign tilbud på garantier &raquo;</Button>
                                </ButtonWrap>
                            </CaseStudyTextWrap>
                        </Row>
                    </Col>

                </Row>
            </Container>
        </CaseStudyWrap>
    )
}

Assurance.propTypes = {
    titleStyle: PropTypes.object
}

Assurance.defaultProps = {
    titleStyle: {
        as:"h3",
        child: {
            color: 'primary'
        }
    },
    textStyle: {
        mt: '30px'
    }
}

export default Assurance;
