import React from 'react';
import PropTypes from 'prop-types'
import { useStaticQuery, graphql, Link} from "gatsby"
import parse from 'html-react-parser'
import {Container, Row, Col} from '../../../components/ui/wrapper'
import Heading from '../../../components/ui/heading'
import Text from '../../../components/ui/text'
import Button from '../../../components/ui/button'
import Image from '../../../components/image'
import {HeroWrapper, HeroTextBox, ImageBoxWrap, ImageBoxOne, ImageBoxTwo} from './hero-area.style'

const HeroArea = (props) => {
    const heroDataQuery = useStaticQuery(graphql `
        query heroQueryData2 {
            infotechnoJson(id: {eq: "infotechno-hero-content"}) {
                image2 {
                    childImageSharp {
                        fluid(maxWidth: 706, maxHeight: 743, quality: 100) {
                            ...GatsbyImageSharpFluid_tracedSVG
                            presentationWidth
                            presentationHeight
                        }
                    }
                }
            }
            site: site {
                siteMetadata {
                    contact {
                        phone
                    }
                }
            }
        }
    `);
    const {image2} = heroDataQuery.infotechnoJson;
    const {subtitleStyle, titleStyle, btnStyle, setFormActive} = props;

    return (
        <HeroWrapper>
            <Container fluid>
                <Row alignitems="center" style={{flexWrap:'wrap-reverse'}}>
                    <Col md={6}>
                        <HeroTextBox>
                            <Heading {...subtitleStyle}>Garantikrav binder opp midler:</Heading>
                            <Heading {...titleStyle}>{parse("Har bedriften behov for å frigjøre garanti-kapital?")}</Heading>
                            <Text className="textLarger">Forsikringsgarantier {/*er et relativt nytt produkt i markedet som*/} <span className="underline">erstatter tradisjonelle bankgarantier</span> gjennom at garantikravet forsikres av et forsikringsselskap, uten at det er et krav om å binde opp kapital.</Text>
                            <Text className="textLarger">Motta en <span className="underline">gratis og uforpliktende</span> prissammenligning for bedriftens garantibehov.</Text>
                            <Button {...btnStyle} onClick={() => {setFormActive(true)}}>Få tilbud &raquo;</Button>
                            {heroDataQuery.site.siteMetadata.contact.phone && heroDataQuery.site.siteMetadata.contact.phone.length > 0 &&
                                <>
                                    &nbsp;&nbsp;
                                    <Button to={"tel:"+heroDataQuery.site.siteMetadata.contact.phone} {...btnStyle} style={{background: 'white', border: '2px solid #086AD8', color: '#086AD8'}}>Ring</Button>
                                    {/*<Button as={Link} to={"/hva-er-en-garantiforsikring"} {...btnStyle} style={{background: 'white', border: '2px solid #086AD8', color: '#086AD8'}}>Les mer om garantiforsikringer</Button>*/}
                                </>
                            }
                            <br/><br/>
                        </HeroTextBox>
                    </Col>
                    <Col md={6}>
                        <ImageBoxWrap>
                            {image2 && (
                                <ImageBoxTwo>
                                    <Image fluid={image2.childImageSharp.fluid} align="left" alt="Garantiforsikringer"/>
                                </ImageBoxTwo>
                            )}
                        </ImageBoxWrap>
                    </Col>
                </Row>
            </Container>
        </HeroWrapper>
    )
}

HeroArea.propTypes = {
    subtitleStyle: PropTypes.object,
    titleStyle: PropTypes.object,
    btnStyle: PropTypes.object
}

HeroArea.defaultProps = {
    subtitleStyle: {
        as: 'h6',
        color: 'primary',
        fontweight: 500,
        mb: '20px'
    },
    titleStyle: {
        as: 'h1',
        mb: '15px',
        fontweight: 500
    },
    btnStyle: {
        hover: '2',
        mt: '15px'
    }
}

export default HeroArea;
