import React, { useState } from "react"
import SEO from "../components/seo"
import Layout from '../containers/layout/layout'
import Header from '../containers/layout/header/header-one'
import Footer from '../containers/layout/footer/footer-two'
import Hero from '../containers/index/hero'
import ClientsArea from '../containers/global/clients-area/section-one'
import FeaturesArea from '../containers/index/features-area'
import AboutArea from '../containers/index/about-area'
import Assurance from '../containers/index/assurance'
import ServicesArea from '../containers/index/services-area'
import AboutServiceWrap from '../containers/index/about-service-wrap'
import FunFactArea from '../containers/global/funfact-area/section-two'
import VideoArea from '../containers/index/video-area'
import CaseStudyArea from '../containers/index/case-study-area'
import TestimonialArea from '../containers/global/testimonial-area/section-two'
import BlogArea from '../containers/index/blog-area'
import ContactArea from '../containers/index/contact-area'
import {useStaticQuery,graphql} from "gatsby";
import ProductForm from "../components/forms/product-form"

const Index = ({location}) => {

    const [formActive,setFormActive] = useState(false)

    const data = useStaticQuery(graphql`
        query
            {
            products: allSanityGuaranteeProduct(filter: {active: {eq: true}}, sort: {fields: name, order: ASC}){
                edges{
                    node{
                        _id
                          explanation
                          name
                          bygg
                          slug {
                            current
                          }
                          image {
                            asset {
                              fixed(width:225, height:145){
                                    ...GatsbySanityImageFixed
                                }
                            }
                          }
                          introduction
                          industry {
                            name
                          }
                          substitute_products
                          alt_names
                    }
                }
            }
        
            industries: allSanityGuaranteeIndustry(filter: {bygg: {eq: true}}, sort: {fields: name,order: ASC}) {
                edges {
                    node {
                        name
                        bygg
                        slug {
                            current
                        }
                    }
                }
            }
        }
    `)

    return(
        <Layout location={location}>
            <SEO
                title="Garantier | Få uforpliktende tilbud fra flere aktører"
                description={"Få uforpliktende tilbud på bedriftens garantibehov fra flere selskaper. En garanti utstedt av et forsikringsselskap, en garantiforsikring, er likestilt med bankgaranti og frigjør bedriftens kapital."}
              /**

                image={}
                keywords={}
                **/
            />
            <Header/>
            <main className="site-wrapper-reveal">
                <Hero
                  setFormActive={setFormActive}
                />
              {formActive &&
              <ProductForm
                setFormActive={setFormActive}
                formTitle={"Få tilbud på garanti"}
                formDesc={"Fyll ut skjemaet nedenfor samt beskriv det konkrete garantibehovet nedenfor kort. Avhengig av kompleksitet tar vi kontakt for ytterligere detaljer før vi innhenter tilbud fra aktuelle leverandører i markedet."}
                formActive={formActive}
              />
              }

              {/*<ClientsArea/>*/}
                <Assurance
                  setFormActive={setFormActive}
                />
                <FeaturesArea industries={data.industries} products={data.products}/>
                <AboutServiceWrap>
                    <AboutArea industries={data.industries} products={data.products}/>
                  {/*<ServicesArea industries={data.industries} products={data.products}/>*/}
                </AboutServiceWrap>
              {/*<FunFactArea/>
                <VideoArea/>
                <CaseStudyArea/>
                <TestimonialArea/>*/}
              {/*<BlogArea/>*/}
                <ContactArea/>
            </main>
            <Footer
              setFormActive={setFormActive}
            />
        </Layout>
    )
}

export default Index;
